<template>
  <div class="title_style">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  name: 'HcYmhfTemplateHeader',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.title_style{
    font-size: 16px;
    font-weight: 600;
    color: #1D2129;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
}
</style>
