<template>
  <div>
    <div class="box_top" style="width:100%;">
      <div class="flex" style="width: 60%;">
        <templateHeader style="margin-bottom: 0;" title="已建在建大中型水利水电工程批（核）准" />

        <div style="margin-left: 10px;">
          <el-select size="mini" v-model="OrgId" @change="changeOrgPids" placeholder="四川省">
            <el-option v-for="item in treeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>

        <div style="margin-left: 10px;">
          <el-select size="mini" v-model="projectTypeId" @change="changeProjectTypeId" placeholder="请选择">
            <el-option v-for="item in projectTypeArr" :key="item.id" :label="item.value" :value="item.id"> </el-option>
          </el-select>
        </div>

        <div style="margin-left: 10px;">
          <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
        </div>

        <div style="margin-left: 10px;">
          <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
        </div>
      </div>
      <div class="export_box">
        <el-button type="primary" size="mini" plain icon="el-icon-download" @click="exportExcel">导出</el-button>
        <el-button type="primary" size="mini" icon="el-icon-upload2" @click="importExcel">导入</el-button>
      </div>
    </div>

    <el-row>
      <el-col :span="18">
        <div class="box_item2" id="box_echarts1">
          <div id="echarts11"></div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box_item2" id="box_echarts2">
          <div id="echarts12"></div>
        </div>
      </el-col>
    </el-row>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
  </div>
</template>

<script>
import templateHeader from '@/components/templateView/templateHeader.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'

import elementResizeDetectorMaker from 'element-resize-detector'
import {
  _EngineeringStatisticData,
  _EngineeringStatisticExport
} from '@/api/modular/postImmigrationService/HomePage/hydropowerProjects.js'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { _getLevelRegion } from '@/api/keepHome'

export default {
  name: 'HcYmhfIndex',

  data() {
    return {
      chart1: null,
      chart2: null,
      projectTypeArr: [],
      projectTypeId: null,
      year: null,
      years: [],
      listData: [],
      treeData: [],
      OrgId: null,
      OrgPids: null
    }
  },
  components: {
    templateHeader,
    tempalteImport
  },

  methods: {
    init() {
      this.getLevelRegion()
      this.getPage()
    },

    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 2,
        inclusionLevel: false
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
        }
      })
    },

    setYears(value, years) {
      this.year = value
      this.years = years
      this.init()
    },

    refreshTable() {
      this.getData()
    },

    search() {
      this.$emit('changeFaOrgId', this.OrgId)
      this.getData()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.year = nowYear - 1
      } else {
        this.year = nowYear - 2
      }

      this.OrgId = null
      this.projectTypeId = null

      this.$emit('changeFaOrgId', null)
      this.getPage()
    },

    async getPage() {
      let params = {
        Code: 'xmhf_ProjectType'
      }
      let res = await sysDictTypeDropDown(params)

      if (res.code == 200) {
        let arr = res.data
        let obj = {
          id: null,
          value: '市，州'
        }
        arr.unshift(obj)

        this.projectTypeId = arr.find(ele => ele.id == null)?.id
        this.projectTypeArr = arr
      }

      this.getData()
    },
    getData() {
      let params = {
        year: this.year,
        projectTypeId: this.projectTypeId,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids
      }

      _EngineeringStatisticData(params).then(res => {
        if (res.code == 200) {
          this.listData = res.data

          this.$nextTick(() => {
            setTimeout(() => {
              this.drawEchart1()
              this.drawEchart2()
            }, 0)
          })
        }
      })
    },
    drawEchart1() {
      if (this.chart1) {
        this.chart1.clear()
      } else {
        this.chart1 = this.$echarts?.init(document.getElementById('echarts11'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('box_echarts1'), () => {
        this.chart1.resize()
      })

      let data = []

      this.listData.reverse().forEach(ele => {
        data.push({
          sizeType: ele.sizeType,
          total: ele.total,
          beforeJune2006: ele.beforeJune2006,
          july2006ToLastYear: ele.july2006ToLastYear,
          currentYear: ele.currentYear
        })
      })

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: 'black', // 修改图例文字颜色为白色
            fontSize: 11
          }
        },

        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            splitLine: {
              show: true,
              type: 'dashed'
            },
            data: ['大型', '中型', this.projectTypeId == null ? '合计' : '小计']
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '（%）'
          }
        ],
        series: [
          {
            name: '2006以前',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(ele => ele.beforeJune2006),
            // stack: 'stack',// 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#3F8CFF',
                borderColor: '#3F8CFF',
                barBorderRadius: 12
              }
            },
            margin: [10, 10]
          },
          {
            name: `2006年7月-${this.year - 1}年底`,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(ele => ele.july2006ToLastYear),
            // stack: 'stack', // 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#0DC1E2',
                barBorderRadius: 12,
                borderColor: '#0DC1E2'
              }
            }
          },
          {
            name: this.year + '年',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(ele => ele.currentYear),
            // stack: 'stack', // 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#ff5d2f',
                borderColor: '#ff5d2f',
                barBorderRadius: 12
              }
            }
          },
          {
            name: this.projectTypeId == null ? '合计' : '小计',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(ele => ele.total),
            // stack: 'stack', // 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#FFAB00',
                borderColor: '#FFAB00',
                barBorderRadius: 12
              }
            }
          }
        ]
      }

      option && this.chart1.setOption(option)
    },
    drawEchart2() {
      if (this.chart2) {
        this.chart2.clear()
      } else {
        this.chart2 = this.$echarts?.init(document.getElementById('echarts12'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('box_echarts2'), () => {
        this.chart2.resize()
      })

      let data = []

      this.listData.reverse().forEach(ele => {
        data.push({
          sizeType: ele.sizeType,
          totalCapacity: ele.totalCapacity, //总库容
          totalInstalledPower: ele.totalInstalledPower, //总装机
          immigrationEstimate: ele.immigrationEstimate //移民概算
        })
      })

      let arr1 = [],
        arr2 = [],
        arr3 = []
      this.listData.forEach(ele => {
        if (ele.sizeType == '中型') {
          arr2.push(ele.totalCapacity, ele.totalInstalledPower, ele.immigrationEstimate)
        } else if (ele.sizeType == '大型') {
          arr3.push(ele.totalCapacity, ele.totalInstalledPower, ele.immigrationEstimate)
        } else {
          arr1.push(ele.totalCapacity, ele.totalInstalledPower, ele.immigrationEstimate)
        }
      })

      let option = {
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: 'black', // 修改图例文字颜色为白色
            fontSize: 11
          }
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '4%',
          containLabel: true
        },
        radar: {
          backgroundColor: '#fff', // 设置背景色为白色
          indicator: [{ name: '总库存\n(亿²)' }, { name: '总装机\n(MW)' }, { name: '移民概算\n(亿元)' }],
          center: ['50%', '60%'] // 将雷达图显示在盒子的中间
        },
        series: [
          {
            name: '',
            type: 'radar',
            data: [
              {
                value: arr3,
                name: '大型',
                itemStyle: {
                  // 这里设置该系列的颜色
                  normal: {
                    color: '#3F8CFF',
                    borderColor: '#3F8CFF',
                    barBorderRadius: 12
                  }
                },
                areaStyle: {
                  normal: {
                    shadowBlur: 13,
                    shadowColor: '#3F8CFF',
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 0.2
                  }
                }
              },
              {
                // value: data.map(ele => ele.totalInstalledPower),
                value: arr2,
                name: '中型',
                itemStyle: {
                  // 这里设置该系列的颜色
                  normal: {
                    color: '#0DC1E2',
                    barBorderRadius: 12,
                    borderColor: '#0DC1E2'
                  }
                },
                areaStyle: {
                  normal: {
                    shadowBlur: 13,
                    shadowColor: '#0DC1E2',
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 0.2
                  }
                }
              },
              {
                value: arr1,
                name: this.projectTypeId == null ? '总计' : '小计',
                itemStyle: {
                  // 这里设置该系列的颜色
                  normal: {
                    color: '#FFAB00',
                    borderColor: '#FFAB00',
                    barBorderRadius: 12
                  }
                },
                areaStyle: {
                  normal: {
                    shadowBlur: 13,
                    shadowColor: '#FFAB00',
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 0.2
                  }
                }
              }
            ],
            symbolSize: 0
          }
        ]
      }

      option && this.chart2.setOption(option)
    },
    changeProjectTypeId(value) {
      this.projectTypeId = value
    },

    changeOrgPids(value) {
      let obj = this.treeData.find(ele => ele.id == value)
      this.OrgId = value
      this.OrgPids = obj?.pids
      // this.$emit('changeFaOrgId', this.OrgId)
    },

    exportExcel() {
      let params = {
        year: this.year
      }
      _EngineeringStatisticExport(params).then(res => {
        if (res.status == 200) {
          this.downloadfile(res)
        } else {
          this.$message.error('导出失败')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.year, this.years, '1-2-2')
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-row,
.el-col {
  border: none;
}
.box_top {
  display: flex;
  justify-content: space-between;
}

.box_item2 {
  width: 100%;
  padding-bottom: 10px;
}
#box_echarts2 {
  width: 100%;
}

#box_echarts1,
#box_echarts2 {
  & > #echarts11,
  & > #echarts12 {
    width: 100%;
    height: 260px;
  }
}
</style>
